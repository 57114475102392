import React from "react";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";
import { PRIVACY } from "../helpers/policies";

export const PrivacyPolicy = () => {
  return (
    <div className="faq-wrapper">
      <header>
        <Sidebar />
        <Navbar />
        <div className="container">
          <h2>Bixoli Privacy Policy </h2>
          <p>{PRIVACY}</p>
        </div>
      </header>
      <Footer />
    </div>
  );
};
