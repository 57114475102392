import React from "react";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";

export const CovidPolicy = () => {
  return (
    <div className="faq-wrapper">
      <header>
        <Sidebar />
        <Navbar />
        <div className="container">
          <h2>Bixoli Covid-19 Policy </h2>
          <p>
            Bixoli takes pride in keeping our professionals and consumers safe.
            Bixoli brings  convenience to your doorsteps with mobile
            professionals. That’s why we implement safety as our number one
            priority. All professionals and consumers are required to wear masks
            during their service. Bixoli requires all professionals to sanitize
            and disinfect their workplace upon arrival. Consumers and
            Professionals must sign a covid-19 check form when signing up for
            services, indicating that they are free of the coronavirus and
            haven’t had any exposure or symptoms within the last 14 days. Please
            see attached for the CDC guidelines at www.cdc.gov.
          </p>
        </div>
      </header>
      <section className="container pb-5">
        <h4 className="font-bold">Protocol when accepting services:</h4>
        <p className="mb-4">
          • Wear a face mask covering over your nose and mouth area. • Wash and
          sanitize hands before starting any services • Do not book services if
          you have Covid-19 or related symptoms. Symptoms are indicated at
          www.cdc.gov.
        </p>
        <h4 className="font-bold">Protocol when arriving to service:</h4>
        <p className="mb-4">
          • Wear a face mask covering over your nose and mouth area • Wash and
          sanitize hands and equipment before conducting services • Do not
          accept any jobs if you have Covid-19 or related symptoms. Symptoms are
          indicated at www.cdc.gov
        </p>
        <h4 className="font-bold">Equipment Policy</h4>
        <p className="mb-4">
          All professionals and businesses are required to maintain a high level
          of customer service. Therefore, it is required to have adequate
          professional equipment for each job. Upon arrival, consumers confirm
          that the equipment is satisfactory, and they are content with the
          appearance before a professional begins to provide service at the
          designated location. All professionals and businesses must ensure that
          the equipment is safe, in good condition, and clean. 
        </p>
      </section>
      <Footer />
    </div>
  );
};
