const TERM_OF_SERVICE = `At Bixoli, accessible from Bixoli.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Bixoli and how we use it.

If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.

This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Bixoli. This policy is not applicable to any information collected offline or via channels other than this website.

Consent
By using our website, you hereby consent to our Privacy Policy and agree to its terms.

Information we collect
The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.

If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.

When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.

How we use your information
We use the information we collect in various ways, including to:

Provide, operate, and maintain our website and mobile app
Improve, personalize, and expand our website and mobile app
Understand and analyze how you use our website and mobile app
Develop new products, services, features, and functionality
Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website and mobile app, and for marketing and promotional purposes
Send you emails
Find and prevent fraud
Log Files
Bixoli follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information. Our Privacy Policy was created with the help of the Privacy Policy Generator and the Online Privacy Policy Generator.

Advertising Partners Privacy Policies
You may consult this list to find the Privacy Policy for each of the advertising partners of Bixoli.

Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Bixoli, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.

Note that Bixoli has no access to or control over these cookies that are used by third-party advertisers.

Third Party Privacy Policies
Bixoli's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.

You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.

CCPA Privacy Rights (Do Not Sell My Personal Information)
Under the CCPA, among other rights, California consumers have the right to:

Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.

Request that a business delete any personal data about the consumer that a business has collected.

Request that a business that sells a consumer's personal data, not sell the consumer's personal data.

If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.

GDPR Data Protection Rights
We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:

The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.

The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.

The right to erasure – You have the right to request that we erase your personal data, under certain conditions.

The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.

The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.

The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.

If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.

Children's Information
Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.

Bixoli does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.`;

const PRIVACY = `At Bixoli, accessible from Bixoli.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Bixoli and how we use it.

If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.

This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Bixoli. This policy is not applicable to any information collected offline or via channels other than this website.

Consent
By using our website, you hereby consent to our Privacy Policy and agree to its terms.

Information we collect
The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.

If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.

When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.

How we use your information
We use the information we collect in various ways, including to:

Provide, operate, and maintain our website and mobile app
Improve, personalize, and expand our website and mobile app
Understand and analyze how you use our website and mobile app
Develop new products, services, features, and functionality
Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website and mobile app, and for marketing and promotional purposes
Send you emails
Find and prevent fraud
Log Files
Bixoli follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information. Our Privacy Policy was created with the help of the Privacy Policy Generator and the Online Privacy Policy Generator.

Advertising Partners Privacy Policies
You may consult this list to find the Privacy Policy for each of the advertising partners of Bixoli.

Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Bixoli, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.

Note that Bixoli has no access to or control over these cookies that are used by third-party advertisers.

Third Party Privacy Policies
Bixoli's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.

You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.

CCPA Privacy Rights (Do Not Sell My Personal Information)
Under the CCPA, among other rights, California consumers have the right to:

Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.

Request that a business delete any personal data about the consumer that a business has collected.

Request that a business that sells a consumer's personal data, not sell the consumer's personal data.

If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.

GDPR Data Protection Rights
We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:

The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.

The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.

The right to erasure – You have the right to request that we erase your personal data, under certain conditions.

The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.

The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.

The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.

If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.

Children's Information
Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.

Bixoli does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.`;

const UCG_POLICY = `U.S. Terms of Use
1. Contractual Relationship
These Terms of Use ("Terms") govern your access or use, from within the United
States and its territories and possessions, of the applications, websites, content,
products, and services (the "Services," as more fully defined below in Section 3) made
available in the United States and its territories and possessions by Bixoli USA, LLC
and its parents, subsidiaries, representatives, affiliates, officers and directors
(collectively, "Bixoli "). PLEASE READ THESE TERMS CAREFULLY, AS THEY
CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU AND BIXOLI . In these Terms,
the words "including" and "include" mean "including, but not limited to."
By accessing or using the Services, you confirm your agreement to be bound by these
Terms. If you do not agree to these Terms, you may not access or use the Services.
These Terms expressly supersede prior agreements or arrangements with you. Bixoli
may immediately terminate these Terms or any Services with respect to you, or
generally cease offering or deny access to the Services or any portion thereof, at any
time for any reason.
IMPORTANT: PLEASE REVIEW THE ARBITRATION AGREEMENT SET FORTH BELOW
CAREFULLY, AS IT WILL REQUIRE YOU TO RESOLVE DISPUTES WITH BIXOLI ON
AN INDIVIDUAL BASIS THROUGH FINAL AND BINDING ARBITRATION. BY
ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE
READ AND UNDERSTAND ALL OF THE TERMS OF THIS AGREEMENT AND HAVE
TAKEN TIME TO CONSIDER THE CONSEQUENCES OF THIS IMPORTANT DECISION.
Supplemental terms may apply to certain Services, such as policies for a particular
event, program, activity or promotion, and such supplemental terms will be disclosed
to you in separate region-specific disclosures (e.g., a particular city webpage on Bixoli
.com) or in connection with the applicable Service(s). Supplemental terms are in
addition to, and shall be deemed a part of, the Terms for the purposes of the
applicable Service(s). Supplemental terms shall prevail over these Terms in the event of
a conflict with respect to the applicable Services.
Bixoli may amend the Terms from time to time. Amendments will be effective upon
Bixoli 's posting of such updated Terms at this location or in the amended policies or
supplemental terms on the applicable Service(s). Your continued access or use of the
Services after such posting confirms your consent to be bound by the Terms, as
amended. If Bixoli changes these Terms after the date you first agreed to the Terms
(or to any subsequent changes to these Terms), you may reject any such change by
providing Bixoli written notice of such rejection within 30 days of the date such change
became effective, as indicated in the "Effective" date above. This written notice must 
be provided either (a) by mail or hand delivery to our registered agent for service of
process, c/o Bixoli USA, LLC (the name and current contact information for the
registered agent in each state are available online here), or (b) by email from the email
address associated with your Account to: info@Bixoli.com. In order to be effective, the
notice must include your full name and clearly indicate your intent to reject changes to
these Terms. By rejecting changes, you are agreeing that you will continue to be bound
by the provisions of these Terms as of the date you first agreed to the Terms (or to any
subsequent changes to these Terms).
2. Arbitration Agreement
By agreeing to the Terms, you agree that you are required to resolve any claim that you
may have against Bixoli on an individual basis in arbitration, as set forth in this
Arbitration Agreement. This will preclude you from bringing any class, collective, or
representative action against Bixoli , and also preclude you from participating in or
recovering relief under any current or future class, collective, consolidated, or
representative action brought against Bixoli by someone else.
Agreement to Binding Arbitration Between You and Bixoli .
You and Bixoli agree that any dispute, claim or controversy arising out of or relating to
(a) these Terms or the existence, breach, termination, enforcement, interpretation or
validity thereof, or (b) your access to or use of the Services at any time, whether before
or after the date you agreed to the Terms, will be settled by binding arbitration between
you and Bixoli , and not in a court of law. Notwithstanding the foregoing, where you
allege claims of sexual assault or sexual harassment occurring in connection with your
use of the Services, you may elect to bring those claims in a court of competent
jurisdiction instead of arbitration. Bixoli agrees to honor your election of forum with
respect to your individual sexual assault or sexual harassment claim but in so doing
does not waive the enforceability of this Arbitration Agreement as to any other
provision (including, but not limited to, the waivers provided in the following paragraph,
which will continue to apply in court and arbitration), controversy, claim or dispute.
You acknowledge and agree that you and Bixoli are each waiving the right to a trial by
jury or to participate as a plaintiff or class member in any purported class action or
representative proceeding. Unless both you and Bixoli otherwise agree in writing, any
arbitration will be conducted only on an individual basis and not in a class, collective,
consolidated, or representative proceeding. However, you and Bixoli each retain the
right to bring an individual action in small claims court and the right to seek injunctive
or other equitable relief in a court of competent jurisdiction to prevent the actual or
threatened infringement, misappropriation or violation of a party's copyrights,
trademarks, trade secrets, patents or other intellectual property rights.
Rules and Governing Law.
The arbitration will be administered by the American Arbitration Association ("AAA") in
accordance with the AAA’s Consumer Arbitration Rules and the Supplementary 
Procedures for Consumer Related Disputes (the "AAA Rules") then in effect, except as
modified by this Arbitration Agreement. The AAA Rules are available at www.adr.org or
by calling the AAA at 1-800-778-7879.
The parties agree that the arbitrator ("Arbitrator"), and not any federal, state, or local
court or agency, shall have exclusive authority to resolve any disputes relating to the
interpretation, applicability, enforceability or formation of this Arbitration Agreement,
including any claim that all or any part of this Arbitration Agreement is void or voidable.
The Arbitrator shall also be responsible for determining all threshold arbitrability issues,
including issues relating to whether the Terms are unconscionable or illusory and any
defense to arbitration, including waiver, delay, laches, or estoppel.
Notwithstanding any choice of law or other provision in the Terms, the parties agree
and acknowledge that this Arbitration Agreement evidences a transaction involving
interstate commerce and that the Federal Arbitration Act, 9 U.S.C. § 1 et seq. ("FAA"),
will govern its interpretation and enforcement and proceedings pursuant thereto. It is
the intent of the parties that the FAA and AAA Rules shall preempt all state laws to the
fullest extent permitted by law. If the FAA and AAA Rules are found to not apply to any
issue that arises under this Arbitration Agreement or the enforcement thereof, then that
issue shall be resolved under the laws of the state of California.
Process.
A party who desires to initiate arbitration must provide the other party with a written
Demand for Arbitration as specified in the AAA Rules. (The AAA provides a form
Demand for Arbitration - Consumer Arbitration Rules at www.adr.org or by calling the
AAA at 1-800-778-7879). The Arbitrator will be either (1) a retired judge or (2) an
attorney specifically licensed to practice law in the state of California and will be
selected by the parties from the AAA's roster of consumer dispute arbitrators. If the
parties are unable to agree upon an Arbitrator within seven (7) days of delivery of the
Demand for Arbitration, then the AAA will appoint the Arbitrator in accordance with the
AAA Rules.
Location and Procedure.
Unless you and Bixoli otherwise agree, the arbitration will be conducted in the county
where you reside. If your claim does not exceed $10,000, then the arbitration will be
conducted solely on the basis of documents you and Bixoli submit to the Arbitrator,
unless you request a hearing or the Arbitrator determines that a hearing is necessary. If
your claim exceeds $10,000, your right to a hearing will be determined by the AAA
Rules. Subject to the AAA Rules, the Arbitrator will have the discretion to direct a
reasonable exchange of information by the parties, consistent with the expedited
nature of the arbitration.
Arbitrator's Decision.
The Arbitrator will render an award within the time frame specified in the AAA Rules.
Judgment on the arbitration award may be entered in any court having competent 
jurisdiction to do so. The Arbitrator may award declaratory or injunctive relief only in
favor of the claimant and only to the extent necessary to provide relief warranted by
the claimant's individual claim. An Arbitrator’s decision shall be final and binding on all
parties. An Arbitrator’s decision and judgment thereon shall have no precedential or
collateral estoppel effect. If you prevail in arbitration you will be entitled to an award of
attorneys' fees and expenses, to the extent provided under applicable law. Bixoli will
not seek, and hereby waives all rights Bixoli may have under applicable law to recover
attorneys' fees and expenses if Bixoli prevails in arbitration.
Fees.
Your responsibility to pay any AAA filing, administrative and arbitrator fees will be
solely as set forth in the AAA Rules. However, if your claim for damages does not
exceed $75,000, Bixoli will pay all such fees, unless the Arbitrator finds that either the
substance of your claim or the relief sought in your Demand for Arbitration was
frivolous or was brought for an improper purpose (as measured by the standards set
forth in Federal Rule of Civil Procedure 11(b)).
Changes.
Notwithstanding the provisions in Section I above, regarding consent to be bound by
amendments to these Terms, if Bixoli changes this Arbitration Agreement after the
date you first agreed to the Terms (or to any subsequent changes to the Terms), you
may reject any such change by providing Bixoli written notice of such rejection within
30 days of the date such change became effective, as indicated in the "Effective" date
above. This written notice must be provided either (a) by mail or hand delivery to our
registered agent for service of process, c/o Bixoli USA, LLC (the name and current
contact information for the registered agent in each state are available online here), or
(b) by email from the email address associated with your Account to: change-dr@Bixoli
.com. In order to be effective, the notice must include your full name and clearly
indicate your intent to reject changes to this Arbitration Agreement. By rejecting
changes, you are agreeing that you will arbitrate any dispute between you and Bixoli in
accordance with the provisions of this Arbitration Agreement as of the date you first
agreed to the Terms (or to any subsequent changes to the Terms).
Severability and Survival.
If any portion of this Arbitration Agreement is found to be unenforceable or unlawful for
any reason, (1) the unenforceable or unlawful provision shall be severed from these
Terms; (2) severance of the unenforceable or unlawful provision shall have no impact
whatsoever on the remainder of the Arbitration Agreement or the parties’ ability to
compel arbitration of any remaining claims on an individual basis pursuant to the
Arbitration Agreement; and (3) to the extent that any claims must therefore proceed on
a class, collective, consolidated, or representative basis, such claims must be litigated
in a civil court of competent jurisdiction and not in arbitration, and the parties agree
that litigation of those claims shall be stayed pending the outcome of any individual
claims in arbitration.
3. The Services
The Services comprise mobile applications and related services (each, an
"Application"), which enable users to arrange and schedule transportation, logistics
and/or delivery services and/or to purchase certain goods, including with third party
providers of such services and goods under agreement with Bixoli or certain of Bixoli
's affiliates ("Third Party Providers"). In certain instances the Services may also include
an option to receive transportation, logistics and/or delivery services for an upfront
price, subject to acceptance by the respective Third Party Providers. Unless otherwise
agreed by Bixoli in a separate written agreement with you, the Services are made
available solely for your personal, noncommercial use. YOU ACKNOWLEDGE THAT
YOUR ABILITY TO OBTAIN SERVICES,TRANSPORTATION, LOGISTICS AND/OR
DELIVERY SERVICES THROUGH THE USE OF THE SERVICES DOES NOT
ESTABLISH BIXOLI AS A PROVIDER OF SERVICES, PROVIDER OF
TRANSPORTATION, LOGISTICS OR DELIVERY SERVICES OR AS A
TRANSPORTATION CARRIER.
License.
Subject to your compliance with these Terms, Bixoli grants you a limited, nonexclusive, non-sublicensable, revocable, non-transferable license to: (i) access and use
the Applications on your personal device solely in connection with your use of the
Services; and (ii) access and use any content, information and related materials that
may be made available through the Services, in each case solely for your personal,
noncommercial use. Any rights not expressly granted herein are reserved by Bixoli and
Bixoli 's licensors.
Restrictions.
You may not: (i) remove any copyright, trademark or other proprietary notices from any
portion of the Services; (ii) reproduce, modify, prepare derivative works based upon,
distribute, license, lease, sell, resell, transfer, publicly display, publicly perform,
transmit, stream, broadcast or otherwise exploit the Services except as expressly
permitted by Bixoli ; (iii) decompile, reverse engineer or disassemble the Services
except as may be permitted by applicable law; (iv) link to, mirror or frame any portion of
the Services; (v) cause or launch any programs or scripts for the purpose of scraping,
indexing, surveying, or otherwise data mining any portion of the Services or unduly
burdening or hindering the operation and/or functionality of any aspect of the Services;
or (vi) attempt to gain unauthorized access to or impair any aspect of the Services or
its related systems or networks.
Provision of the Services.
You also acknowledge that the Services may be made available under such brands or
request options by or in connection with: (i) certain of Bixoli 's subsidiaries and
affiliates; or (ii) independent Third Party Providers.
Third Party Services and Content.
The Services may be made available or accessed in connection with third party
services and content (including advertising) that Bixoli does not control. You
acknowledge that different terms of use and privacy policies may apply to your use of
such third party services and content. Bixoli does not endorse such third party
services and content and in no event shall Bixoli be responsible or liable for any
products or services of such third party providers. Additionally, Apple Inc., Google,
Inc., Microsoft Corporation or BlackBerry Limited will be a third-party beneficiary to
this contract if you access the Services using Applications developed for Apple iOS,
Android, Microsoft Windows, or Blackberry-powered mobile devices, respectively.
These third party beneficiaries are not parties to this contract and are not responsible
for the provision or support of the Services in any manner. Your access to the Services
using these devices is subject to terms set forth in the applicable third party
beneficiary's terms of service.
Ownership.
The Services and all rights therein are and shall remain Bixoli 's property or the
property of Bixoli 's licensors. Neither these Terms nor your use of the Services convey
or grant to you any rights: (i) in or related to the Services except for the limited license
granted above; or (ii) to use or reference in any manner Bixoli 's company names,
logos, product and service names, trademarks or services marks or those of Bixoli 's
licensors.
4. Access and Use of the Services
User Accounts.
In order to use most aspects of the Services, you must register for and maintain an
active personal user Services account ("Account"). You must be at least 18 years of
age, or the age of legal majority in your jurisdiction (if different than 18), to obtain an
Account, unless a specific Service permits otherwise. Account registration requires you
to submit to Bixoli certain personal information, such as your name, address, mobile
phone number and age, as well as at least one valid payment method supported by
Bixoli. You agree to maintain accurate, complete, and up-to-date information in your
Account. Your failure to maintain accurate, complete, and up-to-date Account
information, including having an invalid or expired payment method on file, may result
in your inability to access or use the Services. You are responsible for all activity that
occurs under your Account, and you agree to maintain the security and secrecy of your 
Account username and password at all times. Unless otherwise permitted by Bixoli in
writing, you may only possess one Account.
User Requirements and Conduct.
The Service is not available for use by persons under the age of 18. You may not
authorize third parties to use your Account, and you may not allow persons under the
age of 18 to receive services or logistics services from Third Party Providers unless
they are accompanied by you. You may not assign or otherwise transfer your Account
to any other person or entity. You agree to comply with all applicable laws when
accessing or using the Services, and you may only access or use the Services for
lawful purposes (e.g., no transport of unlawful or hazardous materials). You may not in
your access or use of the Services cause nuisance, annoyance, inconvenience, or
property damage, whether to the Third Party Provider or any other party. In certain
instances you may be asked to provide proof of identity or other method of identity
verification to access or use the Services, and you agree that you may be denied
access to or use of the Services if you refuse to provide proof of identity or other
method of identity verification.
Text Messaging and Telephone Calls.
You agree that Bixoli may contact you by telephone or text messages (including by an
automatic telephone dialing system) at any of the phone numbers provided by you or
on your behalf in connection with an Bixoli daccount, including for marketing purposes.
You understand that you are not required to provide this consent as a condition of
purchasing any property, goods or services. You also understand that you may opt out
of receiving text messages from Bixoli at any time, either by texting the word "STOP"
to 89203 using the mobile device that is receiving the messages, or by contacting
info@Bixoli.com. If you do not choose to opt out, Bixoli may contact you.
Bixoli may, in Bixoli 's sole discretion, permit you from time to time to submit, upload,
publish or otherwise make available to Bixoli through the Services textual, audio,
and/or visual content and information, including commentary and feedback related to
the Services, initiation of support requests, and submission of entries for competitions
and promotions ("User Content"). Any User Content provided by you remains your
property. However, by providing User Content to Bixoli , you grant Bixoli a worldwide,
perpetual, irrevocable, transferable, royalty-free license, with the right to sublicense, to
use, copy, modify, create derivative works of, distribute, publicly display, publicly
perform, and otherwise exploit in any manner such User Content in all formats and
distribution channels now known or hereafter devised (including in connection with the
Services and Bixoli 's business and on third-party sites and services), without further
notice to or consent from you, and without the requirement of payment to you or any
other person or entity.
You represent and warrant that: (i) you either are the sole and exclusive owner of all
User Content or you have all rights, licenses, consents and releases necessary to grant
Bixoli the license to the User Content as set forth above; and (ii) neither the User 
Content, nor your submission, uploading, publishing or otherwise making available of
such User Content, nor Bixoli 's use of the User Content as permitted herein will
infringe, misappropriate or violate a third party's intellectual property or proprietary
rights, or rights of publicity or privacy, or result in the violation of any applicable law or
regulation.
You agree to not provide User Content that is defamatory, libelous, hateful, violent,
obscene, pornographic, unlawful, or otherwise offensive, as determined by Bixoli in its
sole discretion, whether or not such material may be protected by law. Bixoli may, but
shall not be obligated to, review, monitor, or remove User Content, at Bixoli 's sole
discretion and at any time and for any reason, without notice to you.
Network Access and Devices.
You are responsible for obtaining the data network access necessary to use the
Services. Your mobile network's data and messaging rates and fees may apply if you
access or use the Services from your device. You are responsible for acquiring and
updating compatible hardware or devices necessary to access and use the Services
and Applications and any updates thereto. Bixoli does not guarantee that the Services,
or any portion thereof, will function on any particular hardware or devices. In addition,
the Services may be subject to malfunctions and delays inherent in the use of the
Internet and electronic communications.
5. Payment
You understand that use of the Services may result in charges to you for the services
or goods you receive ("Charges"). Bixoli will receive and/or enable your payment of the
applicable Charges for services or goods obtained through your use of the Services.
Charges will be inclusive of applicable taxes where required by law. Charges may
include other applicable fees, tolls, and/or surcharges including a booking fee,
municipal tolls, airport surcharges or processing fees for split payments. Please
visit www.Bixoli.com for further information on your particular location.
All Charges and payments will be enabled by Bixoli using the preferred payment
method designated in your Account, after which you will receive a receipt by email. If
your primary Account payment method is determined to be expired, invalid or
otherwise not able to be charged, you agree that Bixoli may use a secondary payment
method in your Account, if available. Charges paid by you are final and non-refundable,
unless otherwise determined by Bixoli .
As between you and Bixoli , Bixoli reserves the right to establish, remove and/or revise
Charges for any or all services or goods obtained through the use of the Services at
any time in Bixoli 's sole discretion. Further, you acknowledge and agree that Charges
applicable in certain geographical areas may increase substantially during times of high
demand. Bixoli will use reasonable efforts to inform you of Charges that may apply, 
provided that you will be responsible for Charges incurred under your Account
regardless of your awareness of such Charges or the amounts thereof. Bixoli may from
time to time provide certain users with promotional offers and discounts that may
result in different amounts charged for the same or similar services or goods obtained
through the use of the Services, and you agree that such promotional offers and
discounts, unless also made available to you, shall have no bearing on your use of the
Services or the Charges applied to you. You may elect to cancel your request for
Services at any time prior to the commencement of such Services, in which case you
may be charged a cancellation fee on a Third Party Provider’s behalf. After you have
received services or goods obtained through the Service, you will have the opportunity
to rate your experience and leave additional feedback. Bixoli may use the proceeds of
any Charges for any purpose, subject to any payment obligations it has agreed to with
any Third Party Providers or other third parties.
In certain cases, with respect to Third Party Providers, Charges you incur will be owed
directly to Third Party Providers, and Bixoli will collect payment of those charges from
you, on the Third Party Provider’s behalf as their limited payment collection agent, and
payment of the Charges shall be considered the same as payment made directly by
you to the Third Party Provider. In such cases, you retain the right to request lower
Charges from a Third Party Provider for services or goods received by you from such
Third Party Provider at the time you receive such services or goods, and Charges you
incur will be owed to the Third Party Provider. Bixoli will respond accordingly to any
request from a Third Party Provider to modify the Charges for a particular service or
good. This payment structure is intended to fully compensate a Third Party Provider, if
applicable, for the services or goods obtained in connection with your use of the
Services. In all other cases, Charges you incur will be owed and paid directly to Bixoli
or its affiliates, where Bixoli is solely liable for any obligations to Third Party Providers.
In such cases, you retain the right to request lower Charges from Bixoli for services or
goods received by you from a Third Party Provider at the time you receive such
services or goods, and Bixoli will respond accordingly to any request from you to
modify the Charges for a particular service or good. Bixoli does not designate any
portion of your payment as a tip or gratuity to a Third Party Provider. Any
representation by Bixoli (on Bixoli 's website, in the Application, or in Bixoli 's
marketing materials) to the effect that tipping is "voluntary," "not required," and/or
"included" in the payments you make for services or goods provided is not intended to
suggest that Bixoli provides any additional amounts, beyond those described above,
to a Third Party Provider you may use. You understand and agree that, while you are
free to provide additional payment as a gratuity to any Third Party Provider who
provides you with services or goods obtained through the Service, you are under no
obligation to do so. Gratuities are voluntary.
Repair, Cleaning or Lost and Found Fees.
You shall be responsible for the cost of repair for damage to, or necessary cleaning of,
vehicles and property resulting from use of the Services under your Account in excess 
of normal "wear and tear" damages and necessary cleaning ("Repair or Cleaning"). In
the event that a Repair or Cleaning request is verified by Bixoli in Bixoli 's reasonable
discretion, Bixoli reserves the right to facilitate payment for the reasonable cost of
such Repair or Cleaning using your payment method designated in your Account. Such
amounts, as well as those pertaining to lost and found goods, will be transferred by
Bixoli to a Third Party Provider, if applicable, and are non-refundable and may be
updated from time to time solely by Bixoli .
6. Disclaimers; Limitation of Liability;
Indemnity.
DISCLAIMER.
THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." BIXOLI DISCLAIMS
ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY,
NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED
WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
AND NON-INFRINGEMENT. IN ADDITION, BIXOLI MAKES NO REPRESENTATION,
WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS,
QUALITY, SUITABILITY, OR AVAILABILITY OF THE SERVICES OR ANY SERVICES OR
GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE
SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. BIXOLI DOES NOT
GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY
PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF
THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION
THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED
UNDER APPLICABLE LAW.
LIMITATION OF LIABILITY.
BIXOLI SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL,
EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST
PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO,
IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE
SERVICES, REGARDLESS OF THE NEGLIGENCE (EITHER ACTIVE, AFFIRMATIVE,
SOLE, OR CONCURRENT) OF BIXOLI , EVEN IF BIXOLI HAS BEEN ADVISED OF THE
POSSIBILITY OF SUCH DAMAGES.
BIXOLI SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES
ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR
INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR
RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, EVEN IF BIXOLI
HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
THE SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE SERVICES,
GOODS, OR LOGISTICS SERVICES WITH THIRD PARTY PROVIDERS, BUT YOU
AGREE THAT BIXOLI HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO
ANY SERVICES, TRANSPORTATION, GOODS OR LOGISTICS SERVICES PROVIDED
TO YOU BY THIRD PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN
THESE TERMS.
THE LIMITATIONS AND DISCLAIMER IN THIS SECTION DO NOT PURPORT TO LIMIT
LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE
EXCLUDED UNDER APPLICABLE LAW. BECAUSE SOME STATES OR
JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR THE LIMITATION OF
LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR
JURISDICTIONS, BIXOLI ’S LIABILITY SHALL BE LIMITED TO THE EXTENT
PERMITTED BY LAW. THIS PROVISION SHALL HAVE NO EFFECT ON BIXOLI ’S
CHOICE OF LAW PROVISION SET FORTH BELOW.
Indemnity.
You agree to indemnify and hold Bixoli and its affiliates and their officers, directors,
employees, and agents harmless from any and all claims, demands, losses, liabilities,
and expenses (including attorneys' fees), arising out of or in connection with: (i) your
use of the Services or services or goods obtained through your use of the Services; (ii)
your breach or violation of any of these Terms; (iii) Bixoli 's use of your User Content; or
(iv) your violation of the rights of any third party, including Third Party Providers.
7. Other Provisions
Choice of Law.
These Terms are governed by and construed in accordance with the laws of the State
of California, U.S.A., without giving effect to any conflict of law principles, except as
may be otherwise provided in the Arbitration Agreement above or in supplemental
terms applicable to your region. However, the choice of law provision regarding the
interpretation of these Terms is not intended to create any other substantive right to
non-Californians to assert claims under California law whether that be by statute,
common law, or otherwise. These provisions, and except as otherwise provided in
Section 2 of these Terms, are only intended to specify the use of California law to
interpret these Terms and the forum for disputes asserting a breach of these Terms,
and these provisions shall not be interpreted as generally extending California law to
you if you do not otherwise reside in California. The foregoing choice of law and forum
selection provisions do not apply to the arbitration clause in Section 2 or to any
arbitrable disputes as defined therein. Instead, as described in Section 2, the Federal
Arbitration Act shall apply to any such disputes.
Claims of Copyright Infringement.
Claims of copyright infringement should be sent to Bixoli 's designated agent. Please
visit Bixoli 's web page at https://www.Bixoli.com for additional information.
Notice.
Bixoli may give notice by means of a general notice on the Services, electronic mail to
your email address in your Account, telephone or text message to any phone number
provided in connection with your account, or by written communication sent by first
class mail or pre-paid post to any address connected with your Account. Such notice
shall be deemed to have been given upon the expiration of 48 hours after mailing or
posting (if sent by first class mail or pre-paid post) or 12 hours after sending (if sent by
email or telephone). You may give notice to Bixoli , with such notice deemed given
when received by Bixoli , at any time by first class mail or pre-paid post to our
registered agent for service of process, c/o Bixoli USA, LLC. The name and current
contact information for the registered agent in each state are available online here.
General.
You may not assign these Terms without Bixoli 's prior written approval. Bixoli may
assign these Terms without your consent to: (i) a subsidiary or affiliate; (ii) an acquirer
of Bixoli 's equity, business or assets; or (iii) a successor by merger. Any purported
assignment in violation of this section shall be void. No joint venture, partnership,
employment, or agency relationship exists between you, Bixoli or any Third Party
Provider as a result of this Agreement or use of the Services. If any provision of these
Terms is held to be invalid or unenforceable, such provision shall be struck and the
remaining provisions shall be enforced to the fullest extent under law. Bixoli 's failure to
enforce any right or provision in these Terms shall not constitute a waiver of such right
or provision unless acknowledged and agreed to by Bixoli in writing. This provision
shall not affect the Severability and Survivability section of the Arbitration Agreement of
these Terms.`;

export { TERM_OF_SERVICE, PRIVACY, UCG_POLICY };
