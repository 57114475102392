const IDS = {
  SEND_WELCOME_EMAIL:
    "https://us-central1-bixoli.cloudfunctions.net/sendWelcomeEmail",
  SEND_ADMIN_EMAIL: "https://us-central1-bixoli.cloudfunctions.net/sendEmail",
  PLAYSTORE_LINK:
    "https://play.google.com/store/apps/details?id=com.hollainc.bixoli",
  APPSTORE_LINK: "https://apps.apple.com/us/app/bixoli/id1523190790",
};

export { IDS };
