import React from "react";

export const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h4>Bixoli</h4>
            <p>
              Bixoli brings convenience to your doorsteps with mobile
              professionals. You can have the freedom and safety to choose a
              variety of services at your own satisfaction. We bring opportunity
              and comfort through our technology.
            </p>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-6">
                <h3>Support</h3>
                <a href="https://www.facebook.com/BixoliApp">Facebook</a> <br />
                {/* <a href="#">Twitter</a>  <br />*/}
                <a href="https://www.instagram.com/bixoli/">Instagram</a>
              </div>
              <div className="col-lg-6 col-6">
                <h3>Company</h3>
                {/* <a href="#">Users</a>
                <br />
                <a href="#">Businesses</a>
                <br />
                <a href="#">Professionals</a>
                <br /> */}
                <a href="/privacy">Privacy policy</a>
                <br />
                <a href="/term-of-use">Term of services</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
