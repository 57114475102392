import React, { useState } from "react";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";
import shape1 from "../assets/img/shape1.png";
import bg from "../assets/img/bg-s10.png";
import professional from "../assets/img/professional.png";
import business from "../assets/img/business.png";
import curve from "../assets/img/curve.svg";
import app from "../assets/img/phone2.png";
import { Footer } from "../components/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { IDS } from "../helpers/constants";
import { isAndroid, isIOS } from "react-device-detect";
import { validateEmail } from "../helpers/helper";

export const Home = () => {
  const [email, setEmail] = useState("");

  const sendMail = async () => {
    if (!email) return;

    if (!validateEmail(email)) {
      alert("Invalid email address");
      return;
    }

    let adminEmail = await axios({
      method: "POST",
      url: IDS.SEND_ADMIN_EMAIL,
      data: {
        username: "",
        destination: email,
        subject: "Get Bixoli",
        content: `Hello, This is Pete from bixoli. Thanks you for your interest in Bixoli, Our goal is to deliver an experience that is tailored to your personal needs..<br> Please Click to Download our App for iOS Device on <a href="https://apps.apple.com/us/app/bixoli/id1523190790">App store </a> or For Android on <a href="https://play.google.com/store/apps/details?id=com.hollainc.bixoli">Google Play store</a>`,
      },
    }).catch((error) => {
      console.log("Email errow: ", error);
    });

    console.log("Email response: ", adminEmail._response);
    setEmail("");
  };

  const navigateAppStores = () => {
    if (isAndroid) {
      window.open(IDS.PLAYSTORE_LINK, "_blank");
    } else {
      window.open(IDS.APPSTORE_LINK, "_blank");
    }
  };

  return (
    <div className="home-wrapper">
      <header>
        <Sidebar />
        <Navbar />
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-7 col-md-10 col-sm-12">
              <p className="tagline">
                Leisure at your <br /> pleasure while keeping safe.
              </p>
              <p className="sub-tagline">
                Mobile professionals - Arizona and Califonia.
              </p>

              <div>
                <button
                  onClick={() => {
                    navigateAppStores();
                  }}
                  className="btnApp"
                >
                  Download App
                </button>
              </div>


              {/* <div className={"container-input"}>
                <img src={shape1} alt="" className="shape1" />

                <div className="get-started-input">
                  <div className="w-60">
                    <span>Get Started Now !!!</span>
                  </div>

                  <div className="w-40">
                    <button
                      onClick={() => {
                        navigateAppStores();
                      }}
                      className="btn"
                    >
                      Download App
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-lg-5 mt-auto">
              <img src={bg} alt="" className="header-img" />
            </div>
          </div>
        </div>
      </header>
      <section className="business-prof-category my-5">
        <div className="container">
          <h4>Business and Professional categories</h4>
          <p>These are some of the categories we work with</p>
          <div className="row justify-content-center">
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3">
              <div className="icons">
                <span
                  class="iconify"
                  data-icon="ion:fitness-outline"
                  data-inline="false"
                ></span>
              </div>
              <p>Athletics/Sportstrainer</p>
            </div>

            <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3">
              <div className="icons">
                <span
                  class="iconify"
                  data-icon="file-icons:styledcomponents"
                  data-inline="false"
                ></span>
              </div>
              <p> Stylist</p>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3">
              <div className="icons">
                <span
                  class="iconify"
                  data-icon="carbon:cabin-care"
                  data-inline="false"
                ></span>
              </div>
              <p>Nanny</p>
            </div>

            <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3">
              <div className="icons">
                <span
                  class="iconify"
                  data-icon="carbon:security"
                  data-inline="false"
                ></span>
              </div>
              <p>Security</p>
            </div>

            <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3">
              <div className="icons">
                <span
                  class="iconify"
                  data-icon="tabler:physotherapist"
                  data-inline="false"
                ></span>
              </div>
              <p> Therapist</p>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3">
              <div className="icons">
                <span
                  class="iconify"
                  data-icon="map:electrician"
                  data-inline="false"
                ></span>
              </div>
              <p>Electrician</p>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3">
              <div className="icons">
                <span
                  class="iconify"
                  data-icon="simple-icons:altiumdesigner"
                  data-inline="false"
                ></span>
              </div>
              <p>Graphic Designer</p>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3">
              <div className="icons">
                <span
                  class="iconify"
                  data-icon="carbon:clean"
                  data-inline="false"
                ></span>
              </div>
              <p>House Cleaner</p>
            </div>

            <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3">
              <div className="icons">
                <span
                  class="iconify"
                  data-icon="map:painter"
                  data-inline="false"
                ></span>
              </div>
              <p>Painter</p>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3">
              <div className="icons">
                <span
                  class="iconify"
                  data-icon="bi:camera-video"
                  data-inline="false"
                ></span>
              </div>
              <p>Videographer</p>
            </div>

            <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3">
              <div className="icons">
                <span
                  class="iconify"
                  data-icon="bx:bxs-car-mechanic"
                  data-inline="false"
                ></span>
              </div>
              <p>Auto Mechanic</p>
            </div>
          </div>
        </div>
        <div className="curve">
          <img src={curve} alt="" />
        </div>
      </section>
      <section className="professional container py-5" id="professional">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 p-r mb-2">
            <img src={professional} alt="" className="prof-img" />
            <div className="overflow-text">
              <b>Yashira R.</b>
              <br />
              2:00pm - 9:30pm <br />
              Hair Stylist. Az
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 my-auto">
            <p>FOR PROFESSIONALS</p>
            <h3 className="font-bold">The best place to get things done</h3>
            <p className=" text-grey">Thousands of professionals use bixoli</p>
            <p className="">
              - Set your own schedule <br />
              - Earn more money on every shift <br />- Zero signup fee
            </p>
            <button
              className="btn btn-black mt-4"
              onClick={() => navigateAppStores()}
            >
              Download App
            </button>
          </div>
        </div>
      </section>
      <section className="professional container py-5" id="business">
        <div className="row sm-reverse">
          <div className="col-lg-6 col-md-6 col-sm-12 my-auto">
            <p>FOR BUSINESSES</p>
            <h3 className="font-bold">Work where you want, when you want</h3>
            <p className=" text-grey">Hundreds of busineses use bixoli</p>
            <p className="">
              - Add your professionals <br />
              - Get jobs specific to your niche
              <br />- Zero signup fee
            </p>
            <button
              className="btn btn-black mt-4"
              onClick={() => navigateAppStores()}
            >
              {" "}
              Download App
            </button>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 p-r mb-2">
            <img src={business} alt="" className="prof-img" />
            <div className="overflow-text">
              <b>AceMove</b>
              <br />
              8:00am - 11:00pm <br />
              Moving Company. CA.
            </div>
          </div>
        </div>
      </section>

      <section className="services">
        <div className=" container">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 my-auto">
              <h2>The Bixoli Way</h2>
              <h3 className="text-purple font20 font-bold">
                Satisfaction Guaranteed
              </h3>
              <p>
                Bixoli brings convenience to your doorsteps with mobile
                professionals. You can have the freedom and safety to choose a
                variety of services at your own satisfaction. We bring
                opportunity and comfort through our technology.
              </p>
              <br />
              <p>
                Time is scarce and safety is at an all time high. Our goal is to
                deliver an experience that is tailored to your personal needs.
                At all times adhering to safety policy while providing excellent
                service.
              </p>
              <Link to="/contact">
                <button className="btn btn-black mt-4">Contact Us</button>
              </Link>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 mt-4 mb-4">
              <img src={app} alt="" className="w-100" />

              {/* <div className="row">
                <div className="col-6 mb-4">
                  <img src={app} alt="" className="w-100" />
                </div>
                <div className="col-6 mb-4">
                  <img src={app} alt="" className="w-100" />
                </div>
                <div className="col-6 mb-4">
                  <img src={app} alt="" className="w-100" />
                </div>
                <div className="col-6 mb-4">
                  <img src={app} alt="" className="w-100" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="trust" id="users">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 col-sm-12 mx-auto">
              <h2 className="font-bold" align="center">
                Businesses all over the world trust Bixoli to build their brands
              </h2>
            </div>
          </div>
          {/* <div className="row">
          <div className="col-lg-3 col-md-4 col-6 mb-3">
            <h3 className="font-bold">10 years</h3>
            <p>in business</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mb-3">
            <h3 className="font-bold">75,000+</h3>
            <p>customers</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mb-3">
            <h3 className="font-bold">100k+</h3>
            <p>monthly blog readers</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mb-3">
            <h3 className="font-bold">1.2m+</h3>
            <p>social followers</p>
          </div>
        </div> */}

          <div className="circle-wrapper mt-5">
            <div>
              <h3>250+</h3>
              <h5>Professionals</h5>
            </div>
            <div className="img-part">
              <span className="dot1 dot"></span>
              <span className="dot2 dot"></span>
              <span className="dot3 dot"></span>
              <div className="part2">
                <p>
                  Top Arizona Job On demand App
                  <br />
                  <span>Bixoli</span>
                </p>
              </div>
            </div>
            <div>
              <h3>10+ </h3>
              <h5>Businesses</h5>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="faq container py-5">
        <h2 className="font-bold">FREQUENTLY ASKED QUESTIONS</h2>
        <hr />
        <h4 className="font-bold">How do you pay?</h4>
        <p className="mb-4">
          Users pay through Stripe. Our online paying services.{" "}
        </p>
        <h4 className="font-bold">
          Can I order more than one service at a time?
        </h4>
        <p className="mb-4">
          Yes, you can order more than one service at a time by selecting each
          service being requested of that given date.{" "}
        </p>
        <h4 className="font-bold">Is Bixoli available everywhere?</h4>
        <p className="mb-4">
          Bixoli is only available in the following cities: <br />
          Los Angeles <br />
          Phoenix{" "}
        </p>
      </section> */}
      <Footer />
    </div>
  );
};
