import "./App.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Home } from "./pages/Home";
import { Contact } from "./pages/contact";
import { Faq } from "./pages/faq";
import { CovidPolicy } from "./pages/Covid-policy";
import { PrivacyPolicy } from "./pages/privacy-policy";
import { TermOfServices } from "./pages/term-of-service";

function App() {
  return (
    <BrowserRouter>
      <div className="body">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/covid-policy" component={CovidPolicy} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/term-of-use" component={TermOfServices} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}
export default App;
