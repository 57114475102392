import React, { useState } from "react";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";
import worker1 from "../assets/img/worker1.jpg";
import axios from "axios";
import { validateEmail } from "../helpers/helper";
import { IDS } from "../helpers/constants";

export const Contact = () => {
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [msgInput, setMsgInput] = useState("");

  const sendMail = async () => {
    if (!nameInput) {
      alert("Please input your name");
      return;
    }

    if (!emailInput) {
      alert("Please input your email");
      return;
    }

    if (!msgInput) {
      alert("Message cannot be empty");
      return;
    }

    if (!validateEmail(emailInput)) {
      alert("Invalid email address");
      return;
    }

    let adminEmail = await axios({
      method: "POST",
      url: IDS.SEND_ADMIN_EMAIL,
      data: {
        username: nameInput,
        destination: "Admins",
        subject: "Bixoli Website - Contact Us",
        content: `${msgInput}`,
      },
    }).catch((error) => {
      console.log("Email errow: ", error);
    });

    console.log("Email response: ", adminEmail._response);

    alert(
      "Your message ahas been sent succesfully. We'll get back to you shortly"
    );
    setNameInput("");
    setEmailInput("");
    setPhoneInput("");
    setMsgInput("");
  };

  return (
    <div className="contact-wrapper">
      <header>
        <Sidebar />
        <Navbar />
        <div className="container">
          <h2>Contact Us</h2>
          <p>Anytime. We’d love to hear from you.</p>
        </div>
      </header>
      <section className="container">
        <div className="my-5 row contact-section">
          <div className="col-lg-3 col-md-4 col-sm-12">
            {/* <h4 className="font20 text-purple">Office</h4>
            <p className="text-white font18">
              29 Mambilla Street, <br />
              Three Arms Zone, <br />
              Aso drive, <br />
              Califonia
            </p> */}
            {/* <h4 className="font20 text-purple">Phone Numbers</h4>
            <p className="text-white font18">
              <a href="tel:1602-600-8748">C: 602-600-8748</a> <br />
              <a href="tel:602-580-6766">O: 602-580-6766</a>
            </p> */}
            <h4 className="font20 text-purple">Connect with us</h4>
            <p className="text-white font18">
              <a href="mailto:support@bixoli.com" className="mr-3">
                support@bixoli.com
              </a>{" "}
              <br />
              <br />
              <a
                href="https://www.facebook.com/BixoliApp"
                target="_blanc"
                className="mr-3"
              >
                <span
                  class="iconify"
                  data-icon="brandico:facebook"
                  data-inline="false"
                ></span>
              </a>
              {/* <a
                href="https://www.twitter.com/bixoli"
                target="_blanc"
                className="mr-3"
              >
                <span
                  class="iconify"
                  data-icon="brandico:twitter-bird"
                  data-inline="false"
                ></span>
              </a> */}
              {/* <a
                href="https://www.linkedin.com/bixoli"
                target="_blanc"
                className="mr-3"
              >
                <span
                  class="iconify"
                  data-icon="brandico:linkedin-rect"
                  data-inline="false"
                ></span>
              </a> */}
              <a
                href="https://www.instagram.com/bixoli/"
                target="_blanc"
                className="mr-3"
              >
                <span
                  class="iconify"
                  data-icon="brandico:instagram"
                  data-inline="false"
                ></span>
              </a>
            </p>
          </div>
          <div className="col-lg-9 col-md-8 col-sm-12">
            <h1>Message</h1>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                <input
                  type="text"
                  placeholder="Your Name"
                  className="form-control"
                  value={nameInput}
                  onChange={(e) => setNameInput(e.target.value)}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                <input
                  type="text"
                  placeholder="Email Address"
                  className="form-control"
                  value={emailInput}
                  onChange={(e) => setEmailInput(e.target.value)}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                <input
                  type="text"
                  placeholder="Phone"
                  className="form-control"
                  value={phoneInput}
                  onChange={(e) => setPhoneInput(e.target.value)}
                />
              </div>
              <div className="col-12 mb-3">
                <textarea
                  rows="6"
                  placeholder="Message"
                  className="form-control"
                  value={msgInput}
                  onChange={(e) => setMsgInput(e.target.value)}
                ></textarea>
              </div>
              <div className="col-12 mb-3 mt-5">
                <button
                  className="click font-bold text-purple btn-contact"
                  onClick={() => {
                    sendMail();
                  }}
                >
                  SEND MESSAGE
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <p className="font18 text-white">
              Let us tailor a service that meets your needs. Tell us a little
              about yourself and we will get back to you as soon as possible.
              <br />
              <br />
              Monday - Friday: 9am - 5pm <br /> Saturday - Sunday: Closed
            </p>
          </div>
          <div className="col-md-8 col-sm-12">
            <img src={worker1} alt="" className="worker-img" />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
