import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/img/logo-red.png";

export const Navbar = () => {
  return (
    <div className="navbar">
      <div className="px-4 d-flex justify-content-between align-items-center py-3 w-100">
        <div className="nav-logo">
          <NavLink to="/">
            <img src={logo} alt="" />
          </NavLink>
        </div>
        <div className="d-flex align-items-center">
          <NavLink className="navlink" to="/" activeClassName="active">
            Home
          </NavLink>
          <a className="navlink" href="/#professional">
            For Professionals
          </a>
          <a className="navlink" href="/#business">
            For Businesses
          </a>
          <a className="navlink" href="/#users">
            Users
          </a>
          <NavLink className="navlink" to="/faq" activeClassName="active">
            FAQ
          </NavLink>
          <NavLink className="navlink" to="/covid-policy" activeClassName="active">
            COVID policy
          </NavLink>
          <NavLink className="navlink pl-5" to="/contact">
            <button className="btn btn-contact">Contact Us</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
