import React from "react";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";
import { TERM_OF_SERVICE } from "../helpers/policies";

export const TermOfServices = () => {
  return (
    <div className="faq-wrapper">
      <header>
        <Sidebar />
        <Navbar />
        <div className="container">
          <h2>Terms of Service</h2>
          <p>{TERM_OF_SERVICE}</p>
        </div>
      </header>
      <Footer />
    </div>
  );
};
