import React from 'react'
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";

export const Faq = () => {
    return (
        <div className='faq-wrapper'>
            <header>
        <Sidebar />
        <Navbar />
        <div className="container">
          <h2>FAQ</h2>
          <p>Frequently asked questions.</p>
        </div>
      </header>
      <section className="container pb-5">
        <h4 className="font-bold">How do you pay?</h4>
        <p className="mb-4">
          Users pay through Stripe. Our online paying services.{" "}
        </p>
        <h4 className="font-bold">
          Can I order more than one service at a time?
        </h4>
        <p className="mb-4">
          Yes, you can order more than one service at a time by selecting each
          service being requested of that given date.{" "}
        </p>
        <h4 className="font-bold">Is Bixoli available everywhere?</h4>
        <p className="mb-4">
          Bixoli is only available in the following cities: <br />
          Los Angeles <br />
          Phoenix{" "}
        </p>
      </section>
      <Footer />
        </div>
    )
}
