import React, { useState } from "react";
import "./sidebar.scope.scss";
import logo from "../assets/img/logo-red.png";
import { Link, NavLink } from "react-router-dom";

export const Sidebar = () => {
  const [isOpen, setisOpen] = useState(false);
  const sidebarToggle = () => {
    setisOpen(!isOpen);
  };
  return (
    <>
      <div
        className={
          isOpen ? "overlay-isOpen sidebar-overlay" : "sidebar-overlay"
        }
      >
        <div className="close" onClick={sidebarToggle}>
          <span
            className="iconify"
            data-icon="clarity:times-line"
            data-inline="false"
          ></span>
        </div>
      </div>

      <div
        className={
          isOpen
            ? "navbar-toggler menu-icon d-none"
            : "navbar-toggler menu-icon"
        }
        onClick={sidebarToggle}
      >
        <span class="iconify" data-icon="grommet-icons:menu" data-inline="false"></span>
      </div>
      <div className="nav-logo-2">
        <NavLink to="/">
          <img src={logo} alt="" />
        </NavLink>
      </div>
      <section className={isOpen ? "sidebar-isOpen sidebar" : "sidebar"}>
        <div className="sidebar-wrapper">
          <div className="side-logo">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="mt-5">
            <NavLink
              className="side-item"
              onClick={sidebarToggle}
              to="/"
              activeClassName="active"
            >
              Home
            </NavLink>

            <a
              className="side-item"
              onClick={sidebarToggle}
              href="/#professional"
            >
              For Professionals
            </a>
            <a className="side-item" onClick={sidebarToggle} href="/#business">
              For Businesses
            </a>
            <a className="side-item" onClick={sidebarToggle} href="/#users">
              Users
            </a>
            <NavLink
              className="side-item"
              onClick={sidebarToggle}
              to="/faq"
              activeClassName="active"
            >
              FAQ
            </NavLink>
            <NavLink
              className="side-item"
              onClick={sidebarToggle}
              to="/covid-policy"
              activeClassName="active"
            >
              COVID policy
            </NavLink>
            <NavLink
              className="side-item"
              onClick={sidebarToggle}
              to="/contact"
              activeClassName="active"
            >
              <button className="btn btn-contact">Contact Us</button>
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
};
